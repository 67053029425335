import React from "react"

import { Box, PageHeader, Paragraph, SEO } from "components"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />

    <PageHeader title="404" />

    <Box as="main">
      <Box
        as="section"
        p={["layout.2", "layout.4"]}
        borderTop="1px solid"
        borderColor="text"
      >
        <Paragraph as="p" size={600}>
          Page not found
        </Paragraph>
      </Box>
    </Box>
  </>
)

export default NotFoundPage
